<template>
    <el-main>
        <el-form class="set-order" ref="form" :model="form" :rules="rules" label-width="150px">
            <!-- <div class="title">商品</div> -->
            <el-form-item class="item" label="普通订单超过：" prop="order_auto_close_expires">
                <el-input placeholder="请输入内容" v-model="form.order_auto_close_expires">
                    <template slot="append">分</template>
                </el-input>
                <span class="sp2">未付款，订单自动关闭</span>
            </el-form-item>
            <el-form-item class="item" label="发货超过：" prop="order_auto_confirm_expires">
                <el-input placeholder="请输入内容" v-model="form.order_auto_confirm_expires">
                    <template slot="append">天</template>
                </el-input>
                <span class="sp2">未收货，订单自动完成</span>
            </el-form-item>
            <el-form-item class="item" label="订单完成超过：" prop="order_auto_close_refound_expires">
                <el-input placeholder="请输入内容" v-model="form.order_auto_close_refound_expires">
                    <template slot="append">天</template>
                </el-input>
                <span class="sp2">自动结束交易，不能申请售后</span>
            </el-form-item>
            <el-form-item class="item" label="订单完成超过：" prop="order_auto_evaluate_expires">
                <el-input placeholder="请输入内容" v-model="form.order_auto_evaluate_expires">
                    <template slot="append">天</template>
                </el-input>
                <span class="sp2">自动五星好评</span>
            </el-form-item>
            <el-form-item class="item" label="订单播报：">
                <div class="audio">
                    <div>
                        <el-switch v-model="form.order_broadcast"></el-switch>
                        <span class="sp2">开启后，当店铺有新订单时，会收到“您有一个新订单”的声音提醒</span>
                    </div>
                    <el-button @click="audio.play()">试听</el-button>
                </div>
            </el-form-item>
            <el-form-item class="item" label="播报重复间隔：" prop="broadcast_repeat_time">
                <el-input placeholder="最长间隔不超过300秒" v-model="form.broadcast_repeat_time">
                    <template slot="append">秒</template>
                </el-input>
                <span class="sp2">后再次提醒订单未处理</span>
            </el-form-item>
            <el-form-item class="item" label="服务订单超过签字：" prop="broadcast_repeat_time">
                <el-input placeholder="请输入内容" v-model="form.broadcast_repeat_time">
                    <template slot="append">天</template>
                </el-input>
                <span class="sp2">自动结束交易，不能申请售后</span>
            </el-form-item>
            <el-form-item class="item" label="服务订单预约超过：" prop="broadcast_repeat_time">
                <el-input placeholder="请输入内容" v-model="form.broadcast_repeat_time">
                    <template slot="append">小时</template>
                </el-input>
                <span class="sp2">如果无技工接单，或不指派技工，则订单自动关闭</span>
            </el-form-item>
            <Preservation @preservation="onSubmit"></Preservation>
        </el-form>
        <!-- 播放音频 -->
        <audio ref="audio" src="../../assets/audio/neworderTZ.mp3"></audio>
    </el-main>
</template>
  
<script>
import Preservation from '@/components/preservation';
export default {
    components: {
        Preservation,
    },
    data () {
        return {
            form: {
                order_auto_close_expires: 60,
                order_auto_confirm_expires: 15,
                // order_auto_close_refound_expires: 7,
                order_auto_evaluate_expires: 7,
                order_broadcast: !0,
                broadcast_repeat_time: '',
                service_order_auto_close_expires: '',
                service_order_auto_confirm_expires: '',
                service_order_auto_client_confirm_expires: '',
                service_order_auto_aftersale_expires: '',
                is_invoicing: 1,
                invoice_remark: '',
                invoice_type: 2,
                company_email: '',
                email_secret: '',
                email_title: '',
                email_content: '',
                order_auto_billing_time: '',
                is_support_without_reason: '',
                seven_without_reason: ''
            },
            rules: {
                order_auto_close_expires: [{ required: true, message: '请填写普通订单超过时间：', trigger: 'blur' }],
                order_auto_confirm_expires: [{ required: true, message: '请填写发货超过时间', trigger: 'blur' }],
                broadcast_repeat_time: [{ required: true, message: '请填写播报重复间隔', trigger: 'blur' }],
                order_auto_evaluate_expires: [{ required: true, message: '请填写订单完成超过时间', trigger: 'blur' }],
                service_order_auto_close_expires: [{ required: true, message: '请填写无人接单关闭时间', trigger: 'blur' }],
                service_order_auto_confirm_expires: [{ required: true, message: '请填写服务订单完成后不能售后时间', trigger: 'blur' }],
                service_order_auto_client_confirm_expires: [{ required: true, message: '请填写服务自动验收时间', trigger: 'blur' }],
                service_order_auto_aftersale_expires: [{ required: true, message: '请填写平台介入时间', trigger: 'blur' }],
                invoice_remark: [{ required: true, message: '请填写开票须知', trigger: 'blur' }],
                company_email: [{ required: true, message: '请填写发票邮箱', trigger: 'blur' }],
                email_secret: [{ required: true, message: '请填写邮箱秘钥', trigger: 'blur' }],
                email_title: [{ required: true, message: '请填写邮件标题', trigger: 'blur' }],
                email_content: [{ required: true, message: '请填写邮件内容', trigger: 'blur' }],
                order_auto_billing_time: [{ required: true, message: '请填写确认收货后多少天内可以开票', trigger: 'blur' }],
            },
            audio: null,
        };
    },
    computed: {
        shopInfo () {
            return this.$store.getters.shopInfo;
        },
    },
    watch: {
        shopInfo: {
            handler (val) {
                this.form.order_auto_close_expires = val.order_auto_close_expires;
                this.form.order_auto_confirm_expires = val.order_auto_confirm_expires;
                // this.form.order_auto_close_refound_expires = val.order_auto_close_refound_expires;
                this.form.order_auto_evaluate_expires = val.order_auto_evaluate_expires;
                this.form.order_broadcast = val.order_broadcast ? !0 : !1;
                this.form.broadcast_repeat_time = val.broadcast_repeat_time;
                this.form.service_order_auto_close_expires = val.service_order_auto_close_expires;
                this.form.service_order_auto_confirm_expires = val.service_order_auto_confirm_expires;
                this.form.service_order_auto_client_confirm_expires = val.service_order_auto_client_confirm_expires;
                this.form.service_order_auto_aftersale_expires = val.service_order_auto_aftersale_expires;
                this.form.is_invoicing = val.is_invoicing;
                this.form.invoice_remark = val.invoice_remark;
                this.form.invoice_type = val.invoice_type;
                this.form.company_email = val.company_email;
                this.form.email_secret = val.email_secret;
                this.form.email_title = val.email_title;
                this.form.email_content = val.email_content;
                this.form.is_support_without_reason = val.is_support_without_reason;
                this.form.seven_without_reason = val.seven_without_reason;
                this.form.order_auto_billing_time = val.order_auto_billing_time;
            },
            immediate: !0,
        },
    },
    mounted () {
        this.audio = this.$refs.audio;
    },
    methods: {
        onSubmit () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let form = JSON.parse(JSON.stringify(this.form));
                    form.order_broadcast = form.order_broadcast ? 1 : 0;
                    this.$axios.post(this.$api.order.setExpires, form).then(res => {
                        if (res.code == 0) {
                            this.$message({
                                message: '保存成功',
                                type: 'success',
                            });
                            this.$store.dispatch('user/getShopInfo');
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    height: 100%;
    padding: 50px 0 80px 100px;
    background: #fff;

    .set-order {
        width: 1000px;

        .title {
            font-size: 15px;
            font-weight: bold;
        }

        .el-form-item {
            margin-bottom: 20px;
        }

        .item {
            font-size: 14px;
            margin-bottom: 20px;

            .el-input,
            .el-textarea {
                width: 600px;
            }

            .sp2 {
                margin-left: 20px;
            }

            .audio {
                width: 600px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}
</style>
  